import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Sections/Navbar';
import HomeScreen from './Components/Pages/HomeScreen';
import AboutUsScreen from './Components/Pages/AboutUsScreen';
import DonationScreen from './Components/Pages/DonationScreen';
import ContactScreen from './Components/Pages/ContactScreen';
import NewsScreen from './Components/Pages/NewsScreen';
import NoticiaDetail from './Components/Pages/NoticiaDetail';

function App() {
  return (
    <BrowserRouter>
      <Navbar />

      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/about" element={<AboutUsScreen />} />
        <Route path="/partners" element={<DonationScreen />} />
        <Route path="/news" element={<NewsScreen />} />
        <Route path="/news/:id" element={<NoticiaDetail />} />
        <Route path="/contact" element={<ContactScreen />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
