import React from 'react'
import DonacionesSection from '../Sections/Donaciones'
import ContactForm from '../Sections/ContactForm'
import ChangePageScreen from '../Utils/ChangePageScreen'


function DonationScreen() {
    return (
        <div className='w-full'>
            <DonacionesSection />
            <ContactForm />

            <ChangePageScreen />
        </div>
    )
}

export default DonationScreen