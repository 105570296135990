import React, { useState } from 'react';
import { Button, Modal } from 'antd';

import { FaCopy } from 'react-icons/fa'


function ModalComponent({ showModal, handleOk, handleCancel, isModalOpen }) {


    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
    }

    return (
        <>

            <Modal title="Datos Para Transferencia" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={
                <div className='flex justify-center gap-5'>
                    <Button key="back" onClick={
                        () => copyToClipboard('Nombre: Asociacion De Nuevas Familias Chile. RUT: 65.230.372-2 E-mail: finanzas@nuevasfamilias.cl Banco Scotiabank Tipo de cuenta: Cuenta Corriente Nº de Cuenta: 000987830506')
                    }>
                        Copiar Datos <FaCopy />
                    </Button>
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Ok
                    </Button>
                </div>
            } >

                <p>Nombre: Asociacion De Nuevas Familias Chile.</p>
                <p>RUT: 65.230.372-2</p>
                <p>E-mail: finanzas@nuevasfamilias.cl</p>
                <p>Banco Scotiabank</p>
                <p>Tipo de cuenta: Cuenta Corriente</p>
                <p>Nº de Cuenta: 000987830506</p>
            </Modal>
        </>
    );
};

export default ModalComponent