import React from 'react'
import emailjs from '@emailjs/browser'
import { useForm } from 'react-hook-form'
import { Alert } from 'antd'

function ContactForm() {

    const [type, setType] = React.useState(null)
    const [message, setMessage] = React.useState(null)


    const { register, handleSubmit, watch, formState: { errors } } = useForm()

    const sendEmail = async (data) => {
        try {
            const response = await emailjs.send('service_pzy4owv', 'template_6rdtybc', data, {
                publicKey: 'vJ4rqZad4sAhKublK'
            })
            if (response.status === 200) {
                setType('success')
                setMessage('Formulario enviado correctamente')
            }
        } catch (error) {
            console.log(error)
            setType('error')
            setMessage('Ocurrió un error al enviar el Formulario')
        }
    }

    return (
        <section class="text-black bg-[#f9f9f9] relative">
            <div class="container px-5 py-24 mx-auto">
                <div class="flex flex-col text-center w-full mb-12">
                    <h1 class="sm:text-3xl text-2xl font-medium title-font text-black">HAZTE SOCIO</h1>
                    <div className='w-20 border-b-4 h-4 border-primary mx-auto mb-6'></div>
                    <p class="lg:w-2/3 mx-auto leading-relaxed text-base">¡Bienvenido a la asociación Nuevas Familias! Al inscribirte, proporciona tus datos de contacto como tu nombre, correo electrónico y número de teléfono. Una vez registrados, te contactaremos para explicarte cómo puedes comenzar a hacer aportes, ya sean económicos, de tiempo o de habilidades. Cada aporte es valioso y contribuye a nuestro objetivo de fortalecer y apoyar a las familias. ¡Esperamos darte la bienvenida a nuestra comunidad muy pronto!
                    </p>
                </div>

                <form onSubmit={handleSubmit(sendEmail)} class="lg:w-1/2 md:w-2/3 mx-auto">
                    <div class="flex flex-wrap -m-2">
                        <div class="p-2 w-1/2">
                            <div class="relative">
                                <label for="name" class="leading-7 text-sm text-black">Nombre Completo</label>
                                <input type="text" id="name" name="name" {...register('name', { required: true })} class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-primary focus:bg-white focus:ring-2 focus:ring-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                        </div>
                        <div class="p-2 w-1/2">
                            <div class="relative">
                                <label for="email" class="leading-7 text-sm text-black">Correo electrónico</label>
                                <input type="email" id="email" name="email" {...register('email', { required: true })} class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-primary focus:bg-white focus:ring-2 focus:ring-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                        </div>
                        <div class="p-2 w-full">
                            <div class="relative">
                                <label for="phone" class="leading-7 text-sm text-black">Número de teléfono</label>
                                <input type="text" id="phone" name="phone" {...register('phone', { required: true })} class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-primary focus:bg-white focus:ring-2 focus:ring-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                        </div>
                        <div class="p-2 w-full">
                            <div class="relative">
                                <label for="message" class="leading-7 text-sm text-black">Mensaje</label>
                                <textarea id="message" name="message" {...register('message', { required: true })} class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-primary focus:bg-white focus:ring-2 focus:ring-primary h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                            </div>
                        </div>

                        <div class="p-2 w-full">
                            <button type='submit' class="flex mx-auto text-white bg-primary border-0 py-2 px-8 focus:outline-none hover:bg-white hover:text-primary rounded text-lg w-full  items-center justify-center transition-all duration-200 hover:border">Contacto</button>
                        </div>
                    </div>
                    <Alert type={type} message={message} className='mt-10' />
                </form>

                {/* <div class="lg:w-1/2 md:w-2/3 mx-auto">
                    <div class="flex flex-wrap -m-2">
                        <div class="p-2 w-1/2">
                            <div class="relative">
                                <label for="name" class="leading-7 text-sm text-black">Nombre Completo</label>
                                <input type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                        </div>
                        <div class="p-2 w-1/2">
                            <div class="relative">
                                <label for="email" class="leading-7 text-sm text-black">Correo electrónico</label>
                                <input type="email" id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                        </div>
                        <div class="p-2 w-full">
                            <div class="relative">
                                <label for="email" class="leading-7 text-sm text-black">Número de teléfono</label>
                                <input type="text" id="phone" name="phone" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                        </div>
                        <div class="p-2 w-full">
                            <div class="relative">
                                <label for="message" class="leading-7 text-sm text-black">Mensaje</label>
                                <textarea id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                            </div>
                        </div>
                        <div class="p-2 w-full">
                            <button class="flex mx-auto text-white bg-primary border-0 py-2 px-8 focus:outline-none hover:bg-white hover:text-primary rounded text-lg w-full  items-center justify-center transition-all duration-200 hover:border">Contacto</button>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    )
}

export default ContactForm