import { useIsPresent, useScroll, useSpring } from 'framer-motion';
import React from 'react'
import { motion } from 'framer-motion';
import Logo from '../../Assets/Logo-header.webp'


function ChangePageScreen() {

    const isPresent = useIsPresent();

    const { scrollYProgress } = useScroll();

    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    return (

        <motion.div
            initial={{ scaleX: 4 }}
            animate={{ scaleX: 0, transition: { duration: 1, ease: "circOut" } }}
            exit={{ scaleX: 1, transition: { duration: 1, ease: "circIn" } }}
            style={{ originX: isPresent ? 0 : 1 }}
            className="privacy-screen"
        >

        </motion.div>
    )
}

export default ChangePageScreen