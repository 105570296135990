import React from 'react'
import AboutUsImg from '../../../Assets/HomeAboutUs.webp'
import { motion } from "framer-motion"

function QuienesSomosSection() {
    return (
        <section class="text-black body-font w-full">
            <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                <motion.div initial={{ translateX: -160 }} whileInView={{ translateX: 0 }} transition={{ duration: 0.5 }} class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 class="title-font sm:text-4xl text-3xl font-medium text-gray-900">¿Quiénes somos?
                    </h1>
                    <div className='w-40 border-b-4 h-4 border-primary mb-4'>

                    </div>
                    <p class="lg:mb-8 leading-relaxed">La Asociación Nuevas Familias Chile es una organización comprometida con la promoción, educación y defensa de las diversas formas de familia, poniendo un énfasis particular en las familias formadas a través de la gestación subrogada.<br /> Nuestra labor se centra en la orientación, apoyo y protección de los derechos de estas familias, aspirando a una sociedad inclusiva y respetuosa con todas las formas de amor y familia.
                    </p>

                </motion.div>
                <div class="lg:max-w-[40%] lg:w-1/2 md:w-1/2 w-5/6">
                    <img class="object-cover object-center rounded-full" alt="hero" src={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/nuevasfamilias/HomeAboutUs.webp'} />
                </div>
            </div>
        </section>
    )
}

export default QuienesSomosSection