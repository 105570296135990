import React from 'react'
import ContactForm from '../Sections/ContactForm'
import ChangePageScreen from '../Utils/ChangePageScreen'


function ContactScreen() {
    return (
        <div className='w-full'>
            <ContactForm />
            <ChangePageScreen />
        </div>
    )
}

export default ContactScreen