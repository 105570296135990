import React from 'react'
import Donations from '../../../Assets/Donations.webp'
import { cn } from '../../../utils/cn.js'
import { debounce } from 'lodash'
import CountUp from 'react-countup';
import ModalComponent from '../../Utils/Modal.jsx';

function DonacionesSection() {

    const [donacion, setDonacion] = React.useState('5000')
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    }


    const debounceChange = debounce((e) => {
        if (e.target.value === '') return setDonacion('Ingrese valor')
        setDonacion(e.target.value)
    }, 1000)

    return (
        <section class="text-black body-font bg-[#f9f9f9] w-full">
            <ModalComponent showModal={showModal} handleCancel={handleCancel} handleOk={handleOk} isModalOpen={isModalOpen} />
            <div class="container px-10 lg:px-10 py-10 lg:py-20  mx-auto">
                <div>
                    <h3 className='text-2xl lg:text-3xl text-center w-full'>HACER UNA DONACIÓN</h3>
                    <div className='w-20 border-b-4 h-4 border-primary mb-10 mx-auto'></div>
                </div>
                <div class="flex flex-col lg:flex-row flex-wrap -mx-4 -mb-10 text-center">
                    <div class="lg:w-1/2 w-full mb-10  flex items-center lg:items-start flex-col gap-4">
                        <p class="leading-relaxed text-lg text-center lg:text-start">Apoya a la Asociación de Nuevas Familias Chile con tus donaciones. Cada aporte nos ayuda a continuar brindando un espacio de comprensión, educación  y apoyo. ¡Con tu ayuda, podemos seguir haciendo la diferencia en la vida de muchas familias!</p>
                        <div class="rounded-lg h-30 lg:w-[50%] overflow-hidden">
                            <img alt="content" class="object-cover object-center h-full w-full" src={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/nuevasfamilias/Donations.webp'} />
                        </div>
                    </div>
                    <div class="lg:w-1/3 w-full mb-10 px-8 bg-gray-100 shadow-lg border rounded-lg py-16 flex gap-16 flex-col md:ml-auto mt-10 md:mt-0">
                        <span class="text-primary font-bold text-4xl">Datos de transferencia</span>
                        {/* <span class="text-primary font-bold text-7xl">{donacion !== 'otros' ? <CountUp start={0} duration={0.5} end={donacion} /> : 'Ingrese valor'}</span> */}
                        <div class="flex flex-col items-center gap-3">
                            {/* <span class="font-bold text-2xl">Datos de transferencia</span> */}
                            {/* <div className='flex gap-5'>
                                <button onClick={() => setDonacion('5000')} class={cn(
                                    'text-primary text-2xl font-bold transition-all',
                                    donacion === '5000' && 'bg-primary text-white p-2 rounded-3xl border-0'
                                )}>$5.000</button>
                                <button onClick={() => setDonacion('10000')} class={cn(
                                    'text-primary text-2xl font-bold transition-all',
                                    donacion === '10000' && 'bg-primary text-white p-2 rounded-3xl border-0'
                                )}>$10.000</button>
                                <button onClick={() => setDonacion('otros')} class={cn(
                                    'text-primary text-2xl font-bold transition-all',
                                    donacion !== '5000' && donacion !== '10000' && 'bg-primary text-white p-2 px-5 rounded-3xl border-0'
                                )}>Otro</button>


                            </div> */}
                            {/* {
                                donacion !== '5000' && donacion !== '10000' && (
                                    <input onKeyUp={(e) => debounceChange(e)} type='number' class='w-1/2 text-center border-2 border-primary rounded-2xl' />
                                )
                            } */}
                        </div>
                        <button onClick={() => showModal()} class="text-white bg-primary border-0 py-2 px-6 focus:outline-none rounded-2xl text-lg mt-5 w-full">Ver Datos</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DonacionesSection